export default [
  {
    id: 0,
    name: "Connor C.",
    date: "3/13/2025",
    location: "Scottsdale, AZ",
    content:
      "We just want to say thank you so much for all your hard work. We would highly recommend Classy Closets to anyone needing help with a project. Good work! 👍",
  },
  {
    id: 1,
    name: "Tess P.",
    date: "3/7/2025",
    location: "Scottsdale, AZ",
    content:
      "I had a vision for my bathroom renovation and couldn't find anything prefabricated that I liked. Classy Closets came in and put my design on paper with a few recommended tweaks and it's better than I dreamed! I now have a spa-like bathroom that I love!",
  },
  {
    id: 2,
    name: "Lorrie C",
    date: "5/19/2020",
    location: "Chandler",
    content:
      "They should be called Classy Closets and more. We will be back when we complete the master bath and our other office! Professional all the way around. Thank you Classy!",
  },
  {
    id: 3,
    name: "G. Schaffer",
    date: "3/3/2025",
    location: "Scottsdale, AZ",
    content:
      "I've found the designers to be extremely knowledgeable and most have more than 20+ experience at Classy Closets, which says a lot about the company. They go above and beyond to satisfy their clients, and resolve all punch list items.",
  },
  {
    id: 4,
    name: "Brad C",
    date: "2/6/2025",
    location: "Scottsdale, AZ",
    content:
      "Exceptional customer service and products!!! Their team did an outstanding job!!!",
  },
  {
    id: 5,
    name: "Austin F.",
    date: "1/27/2025",
    location: "Scottsdale, AZ",
    shortSummary: "Designers were super informative and helpful",
    content:
      "This is my second time using Classy Closets and I cannot say how much I have enjoyed working with them as they helped work with my ideas and budget.",
  },
  {
    id: 6,
    name: "Patty K.",
    date: "2/2/2025",
    location: "Scottsdale, AZ",
    content:
      "Very very good… Stephanie was very knowledgeable and Keep us good ideas and arranged things so well… And both the office and our master bedroom closet. The installers are fabulous and cleaned up so well very nice. I would definitely use them again!",
  }
]
