import React from "react";
import Slider from "react-slick";
import { Container, SliderContainer, ReviewHeading, ReviewCard } from "./styled";
import reviewsData from "../../../data/reviewsData";

const HomePageReviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear'
  };

  return (
    <Container>
      <SliderContainer>
        <ReviewHeading>
          Customer Stories
        </ReviewHeading>
        <Slider {...settings}>
          {reviewsData.map((review, index) => (
            <ReviewCard key={index}>
              <div className="stars">★★★★★</div>
              <div className="review-content">&ldquo;{review.content}&rdquo;</div>
              <div className="review-author">- {review.name}</div>
            </ReviewCard>
          ))}
        </Slider>
      </SliderContainer>
    </Container>
  );
};

export default HomePageReviews;
