import styled from "styled-components";
import { Container as BaseContainer, ReviewHeading as BaseReviewHeading, SliderContainer as BaseSliderContainer } from "../../../components/Reviews/styled";

export const Container = styled(BaseContainer)`
  margin: 0;
  padding: 80px 0;
  position: relative;
  background-image: url('/images/home_page/heers-20.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  @media only screen and (max-width: 768px) {
    background-attachment: scroll;
    background-size: cover;
    background-position: center;
    padding: 40px 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const ReviewHeading = styled(BaseReviewHeading)`
  color: white;
  position: relative;
  margin: 0 0 40px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

export const SliderContainer = styled(BaseSliderContainer)`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;

  .slick-dots {
    bottom: -40px;
    
    li button:before {
      color: white;
    }
    
    li.slick-active button:before {
      color: white;
    }
  }
  .slick-prev{
    left: -10px;
  }
  .slick-next{
    right: -10px; 
  }
  .slick-prev, .slick-next {
    &:before {
      color: #d9d9d9  ;
      font-size: 24px;
    }
  }
`;

export const ReviewCard = styled.div`
  color: white;
  text-align: center;
  padding: 0 40px;

  .stars {
    color: #FFD700;
    font-size: 36px;
    margin-bottom: 20px;
    letter-spacing: 4px;
  }

  .review-content {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    font-style: italic;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  .review-author {
    font-size: 1rem;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
`; 